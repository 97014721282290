<template>
  <b-container>
    <b-row>
      <div class="centered">
        <h1 class="mb-0 mb-3">
          <span>{{ $t("Napísali") }}</span>
          <span class="w-color-primary z-fancy-underline">
            {{ $t("o nás") }}
            <svgFancyUnderline />
          </span>
        </h1>
      </div>
      <AboutNapisaliONas />
    </b-row>
  </b-container>
</template>

<script>
export default {
  components: {
    svgFancyUnderline: () => import("/assets/icons/fancyUnderline.svg?inline"),
    AboutNapisaliONas: () => import("/components/About/AboutNapisaliONas.vue"),
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.centered {
  z-index: 1000;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 2rem;
}
</style>
